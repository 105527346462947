import type { Client } from '@open-rpc/client-js'
import { IEPermitsEntity } from './base';

export interface ReviewTimerDTO extends IEPermitsEntity {
  ReviewTimerID: number;
  ResubmitNumber?: number;
  PermitApplMasterID?: number;
  InspectionID?: number;
  ComplaintID?: number;
  ReviewerID: number;
  RelRoleDepartmentID: number;
  StartTime: string | Date;
  EndTime?: string | Date;
  ProjectNumber?: string;
  InspectionPermitNumber?: string;
  InspectionPermitCode?: string;
  ComplaintNumber?: string;
  DepartmentName?: string;
  RoleName?: string;
}

export async function StartReviewTimer(client: Client,
    PermitApplMasterID: number | undefined = undefined,
    InspectionID: number | undefined = undefined,
    ComplaintID: number | undefined = undefined
  ): Promise<ReviewTimerDTO> {
  return await client.request({ method: 'StartReviewTimer', params: {
    PermitApplMasterID,
    InspectionID,
    ComplaintID,
  } })
}

export async function StopReviewTimers(client: Client): Promise<void> {
  return await client.request({ method: 'StopReviewTimers' })
}

export async function GetOpenReviewTimers(client: Client): Promise<ReviewTimerDTO[]> {
  return await client.request({ method: 'GetOpenReviewTimers' })
}
