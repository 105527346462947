import type { Client } from '@open-rpc/client-js'
import { WebSocketTransport } from '@open-rpc/client-js'

export interface ConnectedUserDTO {
  UserID: number;
  UserName: string;
  ConnectionID: string;
  DepartmentID: number;
  RoleID: number;
}

export interface ChatRoomDTO {
  RoomID: string;
  RoomName: string;
  Members: ConnectedUserDTO[];
}

export interface IEPermitsEntity {
  DateCreated: string | Date;
  CreatedByID: number;
  LastUpdateDate: string | Date;
  LastUpdateID: number;
}

export const ParseDate = (date: string | Date) => typeof date === 'string' ? new Date(date) : date;

export function HasOpenConnection(client: Client): boolean {
  const transport = client.requestManager.getPrimaryTransport();
  return transport instanceof WebSocketTransport && transport.connection.readyState === WebSocket.OPEN;
}

export async function Ping(client: Client): Promise<string> {
  return await client.request({ method: 'Ping' })
}

export async function GetAllRooms(client: Client): Promise<ChatRoomDTO[]> {
  return await client.request({ method: 'GetAllRooms' })
}

export async function GetAllUsers(client: Client): Promise<ConnectedUserDTO[]> {
  return await client.request({ method: 'GetAllUsers' })
}

export async function WhoAmI(client: Client): Promise<ConnectedUserDTO> {
  return await client.request({ method: 'WhoAmI' })
}
