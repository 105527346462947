import { consume } from '@lit/context'
import { css, html } from 'lit'
import type { TemplateResult } from 'lit'
import { customElement, property } from 'lit/decorators.js'
import { ifDefined } from 'lit/directives/if-defined.js';
import { useEffect } from 'haunted'
import { LitHauntedElement } from '../../mixins/lit-haunted-element';
import { jsonRpcClientContext, websocketProviderContext } from './ep-websocket-provider';
import type { Client } from '@open-rpc/client-js'
import type { IJSONRPCNotification } from '@open-rpc/client-js/build/Request'
import { IntervalController } from '../../controllers/interval-controller';
import { GetOpenReviewTimers, HasOpenConnection, ParseDate, ReviewTimerDTO, StartReviewTimer, StopReviewTimers, WhoAmI } from './jsonrpc';

@customElement('ep-review-timer')
export class EPReviewTimer extends LitHauntedElement {
  // Every 1 second, render the interface (used for counting timers)
  private clockInterval = new IntervalController(this, () => this.requestUpdate(), 1000);
  // Every 15 minutes, ask for new data (in case the database was updated by a job overnight)
  private staleInterval = new IntervalController(this, () => this._handlePeriodicStaleRefresh(), 15 * 60 * 1000); 

  // #region Core Properties

  @property({ attribute: false })
  timerStartedAt?: Date;

  @property({ attribute: false })
  isLoading: boolean = true;

  @property({ attribute: false })
  innerConnectionIssue?: TemplateResult<1>;

  get currentConnectionIssue(): TemplateResult<1> | undefined {
    return this.providerConnectionIssue ?? this.innerConnectionIssue;
  }

  set currentConnectionIssue(value: TemplateResult<1> | undefined) {
    this.innerConnectionIssue = value;
  }

  @property({ attribute: false })
  openTimers: ReviewTimerDTO[] = []; // TODO: timer data

  get currentTimer(): ReviewTimerDTO | undefined {
    if (this.PermitApplMasterID !== undefined) {
      return this.openTimers.find(timer => timer.PermitApplMasterID === this.PermitApplMasterID);
    }
    else if (this.InspectionID !== undefined) {
      return this.openTimers.find(timer => timer.InspectionID === this.InspectionID);
    }
    else if (this.ComplaintID !== undefined) {
      return this.openTimers.find(timer => timer.ComplaintID === this.ComplaintID);
    }
    else {
      return undefined;
    }
  }

  get otherTimer(): ReviewTimerDTO | undefined {
    const currentTimer = this.currentTimer;
    return this.openTimers.find(timer => timer.ReviewTimerID !== currentTimer?.ReviewTimerID)
  }

  @property({ type: Number, attribute: 'permitapplmasterid'})
  PermitApplMasterID?: number;

  @property({ type: Number, attribute: 'inspectionid' })
  InspectionID?: number;

  @property({ type: Number, attribute: 'complaintid' })
  ComplaintID?: number;

  @property({ attribute: 'project-link' })
  projectLink?: string;

  @property({ attribute: 'inspection-link' })
  inspectionLink?: string;

  @property({ attribute: 'complaint-link' })
  complaintLink?: string;

  @property({ attribute: 'disabled-reason' })
  disabledReason?: string;

  @property({ type: Boolean, attribute: 'compact', reflect: true })
  isCompactMode: boolean = false;

  @property({ type: Boolean, attribute: 'disabled', reflect: true })
  isDisabled: boolean = false;

  // #endregion

  // #region Context Properties (Provided + Consumed)
  @consume({ context: jsonRpcClientContext, subscribe: true })
  @property({ attribute: false })
  client?: Client;

  @consume({ context: websocketProviderContext, subscribe: true })
  @property({ attribute: false })
  providerConnectionIssue?: TemplateResult<1>;
  // #endregion

  get formattedTimerTicker(): string {
    //console.log('formatting')
    if (this.timerStartedAt === undefined) return `--:--:--`;
    const now = new Date();
    const past = this.timerStartedAt ?? now;
    const secondsDiff = Math.abs(now.valueOf() - past.valueOf()) / 1000;
    let tally = secondsDiff;

    const hours = Math.floor(tally / (60 * 60));
    tally = tally % (60 * 60);
    const minutes = Math.floor(tally / 60);
    tally = tally % 60;
    const seconds = tally;

    const zeropad = (num: number) => Math.floor(num).toString().padStart(2, '0')
    
    return `${zeropad(hours)}:${zeropad(minutes)}:${zeropad(seconds)}`
  }

  render() {
    // Initialize the component if the WSS client is provided
    useEffect(async () => await this.setupClient(), [this.client]);

    useEffect(() => {
      if (this.currentConnectionIssue !== undefined) {
        this.timerStartedAt = undefined;
      }
    }, [this.currentConnectionIssue])

    // If any of those open timers are the one we should be seeing on this page, set the "timerStartedAt" value
    useEffect(() => {
      this.timerStartedAt = this.currentTimer !== undefined ? ParseDate(this.currentTimer.StartTime) : undefined;
      this._dispatchUpdateEvent();
    }, [this.openTimers, this.PermitApplMasterID, this.InspectionID, this.ComplaintID])

    // Automatically re-render the page every second, depending on if a timer is running or not
    useEffect(() => {
      if (this.timerStartedAt !== undefined) {
        this.clockInterval.startInterval();
      }
      else {
        this.clockInterval.stopInterval();
      }
    }, [this.timerStartedAt]);

    // maybe this will make fetching the "otherTimer" less expensive
    const otherTimer = this.otherTimer;
    const currentTimer = this.currentTimer;

    return html`
      <div part="container"
        data-isloading=${(this.isLoading || this.currentConnectionIssue !== undefined).toString()}
        data-istimernull=${(this.timerStartedAt === undefined).toString()}
        data-iscurrenttimernull=${(this.openTimers.length === 0).toString()}
        data-iscompactmode=${this.isCompactMode.toString()}
        data-isdisabled=${this.isDisabled.toString()}
        data-isproblem=${(this.currentConnectionIssue !== undefined).toString()}
      >
        <ep-button part="button" style="--background-color: var(--color-button-bg); --color: var(--color-button-fg);" @click=${this._handleClick}
          ?disabled=${this.isLoading || this.currentConnectionIssue !== undefined || this.isDisabled}
          title=${ifDefined(this.isDisabled ? this.disabledReason : undefined)}>
          ${
            this.isLoading
            ? html`<fluent-progress-ring slot="leading" style="display: inline-flex; height: 60%;"></fluent-progress-ring>`
            : ''
          }
          ${
            this.timerStartedAt === undefined
            ? (
              otherTimer === undefined
              ? 'Start Timer'
              : 'Start New Timer'
            )
            : 'Stop Timer'
          }
        </ep-button>
        <ep-text part="subtitle" variant="caption" style="--color: var(--color-text);">
          ${
            this.currentConnectionIssue !== undefined
            ? html`${this.currentConnectionIssue}`
            : (
              this.timerStartedAt === undefined
              ? (
                otherTimer === undefined
                ? 'No timers in progress'
                : (
                  /* Status Message: Project */
                  otherTimer.PermitApplMasterID !== undefined && this.projectLink !== undefined
                  ? (
                    this.isCompactMode
                    ? html`⏳ You have a Timer in Progress on <a href=${`${this.projectLink}${otherTimer.PermitApplMasterID}`}>Project #${otherTimer.ProjectNumber ?? '???'}</a>`
                    : html`Timer in Progress on <a href=${`${this.projectLink}${otherTimer.PermitApplMasterID}`}>Project #${otherTimer.ProjectNumber ?? '???'}</a>`
                  )
                  /* Status Message: Inspection */
                  : otherTimer?.InspectionID !== undefined
                  ? (
                    this.isCompactMode
                    ? html`⏳ You have a Timer in Progress on <a href=${`${this.inspectionLink}${otherTimer.ProjectNumber}`} target="_blank"><u>${otherTimer.InspectionPermitCode ?? '???'} Inspection</u> for <u>Permit #${otherTimer.InspectionPermitNumber ?? '???'}</u></a>`
                    : html`Timer in Progress on <a href=${`${this.inspectionLink}${otherTimer.ProjectNumber}`} target="_blank"><u>${otherTimer.InspectionPermitCode ?? '???'} Inspection</u> for <u>Permit #${otherTimer.InspectionPermitNumber ?? '???'}</u></a>`
                  )
                  /* Status Message: Complaint */
                  : otherTimer?.ComplaintID !== undefined
                  ? (
                    this.isCompactMode
                    ? html`⏳ You have a Timer in Progress on <a href=${`${this.complaintLink}${otherTimer.ComplaintNumber}`} target="_blank"><u>Complaint #${otherTimer.ComplaintNumber ?? '???'}</u></a>`
                    : html`Timer in Progress on <a href=${`${this.complaintLink}${otherTimer.ComplaintNumber}`} target="_blank"><u>Complaint #${otherTimer.ComplaintNumber ?? '???'}</u></a>`
                  )
                  : html`Timer already in progress`
                )
              )
              : html`
                Timer started at ${new Intl.DateTimeFormat('en', { timeStyle: 'short' }).format(this.timerStartedAt)}
                ${
                  currentTimer?.DepartmentName !== undefined
                  ? ` under ${currentTimer.DepartmentName}`
                  : ''
                }
              `
              )
          }
        </ep-text>
        <ep-text part="timer" font="monospace" style="--color: var(--color-text-timer);">
          ${this.formattedTimerTicker}
        </ep-text>
      </div>
    `
  }

  override connectedCallback() {
    super.connectedCallback();
    if (this.timerStartedAt !== undefined) {
      this.clockInterval.startInterval();
    }
    else {
      this.clockInterval.stopInterval();
    }
    this.staleInterval.startInterval();
  }

  override disconnectedCallback() {
    super.disconnectedCallback()
    this.clockInterval.stopInterval();
    this.staleInterval.stopInterval();
  }

  private async setupClient() {
    if (this.client !== undefined) {
      console.debug(`[${this.tagName}] waiting for client to connect...`)
      this.currentConnectionIssue = html`Establishing connection...`;
      try {
        // Attempts to connect, but specifies a 5000ms timeout
        await Promise.race([
          this.client.requestManager.connectPromise,
          new Promise((resolve, reject) => setTimeout(() => reject("Timeout exceeded"), 30000)), 
        ]);
        this.currentConnectionIssue = undefined;
      }
      catch (err) {
        console.error('[Review Timer] Client failed to connect', err);
        this.currentConnectionIssue = html`There was a problem initializing the Review Timer. Please try refreshing or changing your Department/Role. (1)`;
        return;
      }
      console.debug(`[${this.tagName}] client connected!`);

      // Make sure that our user has the correct session info available
      const userInfo = await WhoAmI(this.client);
      if (userInfo === null || userInfo.DepartmentID === -1 || userInfo.RoleID === -1) {
        console.error('[Review Timer] User info was missing: ', userInfo);
        this.currentConnectionIssue = html`There was a problem initializing the Review Timer. Please try refreshing or changing your Department/Role. (2)`;
      }

      // Check if the user has any timers already open
      this.openTimers = [...await GetOpenReviewTimers(this.client)]
      this.isLoading = false;

      // Setup notifications
      this.client.onNotification((data) => this._handleNotification(data));
      // Show a message when the connection is closed
      // (this.client.requestManager.getPrimaryTransport() as any).connection.addEventListener('close', () => {
      //   this.currentConnectionIssue = 'Your connection to the server was closed. Please try refreshing.';
      // });
      // Show a message when an error happens
      this.client.onError(err => {
        console.error('[Review Timer] RPC Client Error: ', err);
        this.currentConnectionIssue = html`There was a problem with the Review Timer. Please try refreshing.`;
      })
    }
  }

  private async _handleClick(event: MouseEvent) {
    if (this.client !== undefined) {
      this.isLoading = true;
      if (this.timerStartedAt === undefined) {
        await StartReviewTimer(this.client, this.PermitApplMasterID, this.InspectionID, this.ComplaintID)
      }
      else {
        await StopReviewTimers(this.client)
      }
      /**
       * Commented out so we can rely on the Notification to determine when things aren't loading anymore
       * Pros:
       * - Don't flash "isLoading" twice
       * - WebSockets are fast, so this shouldn't be an issue
       * Cons:
       * - Assumes that the server will always send a notification (which should happen???)
       */
      //this.isLoading = false;
    }
  }

  private async _handleNotification(data: IJSONRPCNotification) {
    if (this.client !== undefined) {
      // If either notification happens, get the currently open timers. The UI responds based on what is present there.
      if (data.method === 'ReviewTimerStopped' || data.method === 'ReviewTimerStarted') {
        this.isLoading = true;
        this.openTimers = [...await GetOpenReviewTimers(this.client)]
        this.isLoading = false;
      }
    }
  }

  private async _handlePeriodicStaleRefresh() {
    if (this.client !== undefined && HasOpenConnection(this.client)) {
      console.debug(`[${this.tagName}] 🕸\uFE0F Connection deemed stable. Attempting to refresh due to potentially stale data (${new Date().toLocaleTimeString()}).`);
      this.isLoading = true;
      this.openTimers = [...await GetOpenReviewTimers(this.client)]
      this.isLoading = false;
    }
  }

  private _dispatchUpdateEvent() {
    const event = new CustomEvent('timer-update', { 
      bubbles: true,
      composed: true,
    })
    this.dispatchEvent(event);
  }

  static styles = css`
    :host {
      display: contents;
      --font-mono: ui-monospace, SFMono-Regular, SF Mono, Menlo, "Cascadia Mono", Consolas, Liberation Mono, monospace;
      --color-text: #000;
      --color-text-timer: #000;
      --color-container: blue;
      --color-button-fg: red;
      --color-button-bg: white;
    }

    [part=container][data-iscompactmode=false] {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 12px;
      border-radius: 28px;
      padding: 24px;
      background-color: var(--color-container);
      transition: background-color 150ms;
      border: 1px solid rgba(0, 0, 0, 0.1);
    }
    [part=container][data-iscompactmode=true] {
      display: var(--display-compact, none);
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 12px;
      border-radius: 0px;
      padding: 10px;
      background-color: var(--color-container);
      transition: none;
      border: 1px solid rgba(0, 0, 0, 0.1);
    }

    [data-iscompactmode=true] [part=button], [data-iscompactmode=true] [part=timer], [data-iscompactmode=true] [part=gap] {
      display: none;
    }

    [data-iscompactmode=true] [part=subtitle] {
      font-size: 2.0em;
    }

    [data-iscompactmode=true] a {
      color: deepskyblue;
    }

    [part=subtitle] {
      font-size: 1.3em;
    }
    [data-isproblem=true] [part=subtitle] {
      --color-text: rgba(0, 0, 0, 0.5);
    }

    [part=timer] {
      font-size: 1.5em;
      margin-left: auto;
      white-space: nowrap;
    }

    [data-isloading=true], [data-isdisabled=true] {
      --color-text: rgba(0, 0, 0, 0.26);
      --color-text-timer: rgba(0, 0, 0, 0.26);
      --color-container: rgba(0, 0, 0, 0.12);
      /* --color-button-fg: rgba(255,255,255,0.9); */
      /* --color-button-bg: #026E00; */
    }

    /* [data-isdisabled=true] [part=button] {
      pointer-events: none;
    } */

    [data-isloading=false][data-istimernull=true] {
      /* base: rgb(0,255,0), source: theme picker at https://material-web.dev/ */
      --display-compact: none;
      --color-text: #026E00;
      --color-text-timer: #394B35;
      --color-container: #E5F2DB;
      --color-button-fg: rgba(255,255,255,0.95);
      --color-button-bg: #026E00;
    }

    [data-isloading=false][data-istimernull=true][data-iscurrenttimernull=false] {
      /* base: rgb(255,127,0), source: theme picker at https://material-web.dev/ */
      --display-compact: flex;
      --color-text: #924c00;
      --color-text-timer: #584235;
      --color-container: #ffeadd;
      --color-button-fg: rgba(255,255,255,0.95);
      --color-button-bg: #924c00;
    }

    [data-isloading=false][data-istimernull=true][data-iscurrenttimernull=false][data-iscompactmode=true] {
      /* base: rgb(255,127,0), source: theme picker at https://material-web.dev/ */
      --display-compact: flex;
      --color-container: #924c00;
      --color-text: rgba(255,255,255,0.95);
    }

    [data-isloading=false][data-istimernull=false] {
      /* base: rgb(255,0,0), source: theme picker at https://material-web.dev/ */
      --display-compact: none;
      --color-text: #a50100;
      --color-text-timer: #603E39;
      --color-container: #ffe9e6;
      --color-button-fg: rgba(255,255,255,0.95);
      --color-button-bg: #A50100;
    }

    
  `
}

declare global {
  interface HTMLElementTagNameMap {
    'ep-review-timer': EPReviewTimer
  }
}
