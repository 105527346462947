import { ReactiveController, ReactiveControllerHost } from 'lit';

export class IntervalController implements ReactiveController {
  host: ReactiveControllerHost;

  //value = new Date();
  timeout: number;
  private _timerID?: number;
  private _handler: () => void;

  constructor(host: ReactiveControllerHost, handler: () => void, timeout = 1000) {
    (this.host = host).addController(this);
    this.timeout = timeout;
    this._handler = handler;
  }
  startInterval() {
    if (this._timerID === undefined) {
      //console.log('interval started')
      // Start a timer when the host is connected
      this._timerID = window.setInterval(() => {
        this._handler()
        // this.value = new Date();
        // // Update the host with new value
        // this.host.requestUpdate();
      }, this.timeout);
    }
  }
  stopInterval() {
    //console.log('interval stopped')
    // Clear the timer when the host is disconnected
    window.clearInterval(this._timerID);
    this._timerID = undefined;
  }

  hostConnected() {
    //this.startInterval();
  }
  hostDisconnected() {
    this.stopInterval();
  }
}