import { createContext, provide } from '@lit/context'
import { html, css } from 'lit'
import type { TemplateResult } from 'lit'
import { customElement, property } from 'lit/decorators.js'
import { useEffect } from 'haunted'
import { Client, RequestManager, WebSocketTransport } from '@open-rpc/client-js'
import { LitHauntedElement } from '../../mixins/lit-haunted-element';
import { IntervalController } from '../../controllers/interval-controller'
import { HasOpenConnection, Ping } from './jsonrpc'

export const jsonRpcClientContext = createContext<Client | undefined>(Symbol('ep-websocket-provider_client'))
export const websocketProviderContext = createContext<TemplateResult<1> | undefined>(Symbol('ep-websocket-provider_providerConnectionIssue'))

@customElement('ep-websocket-provider')
export class EPWebSocketProvider extends LitHauntedElement {
  /**
   * This interval attempts to reconnect to the WebSocket every so often.
   * This is intended to prevent the connection from being closed by the load balancer.
   * Whether or not this interval is used or not depends on the `repeatHandshake` property.
   */
  private handshakeInterval = new IntervalController(this, () => this._handleInterval(), 90 * 1000); // 90 seconds
  /**
   * This interval attempts to keep the WebSocket connection "chatty" with a "Ping" message every so often.
   * This is intended to prevent the connection from being closed by the load balancer.
   * A ping message won't be sent unless the connection is detected to be "open" and the previous ping was successful.
   * The "ping" lifecycle is completely separate from the lifecycle of the connection itself.
   */
  private pingInterval = new IntervalController(this, () => this._handlePing(), 60 * 1000); // 60 seconds

  // Something sorta unique to identify instances
  private instance = Math.floor(Math.random() * 1000);

  @property({ attribute: 'service-url', reflect: true })
  serviceUrl: string = '';

  @property({ type: Boolean, attribute: 'repeat-handshake', reflect: true })
  repeatHandshake: boolean = false;

  @property({ attribute: false })
  lastPingFinished: boolean = true;

  @provide({ context: jsonRpcClientContext })
  @property({ attribute: false })
  client: Client | undefined = undefined;

  @provide({ context: websocketProviderContext })
  @property({ attribute: false })
  providerConnectionIssue: TemplateResult<1> | undefined = undefined;

  @property({ attribute: false })
  isAttemptingConnection: boolean = false;

  @property({ attribute: false })
  retryCount: number = 0;

  @property({ attribute: false })
  retryLimit: number = 5;

  @property({ attribute: false })
  retryDelayCountdown: number = 0;

  @property({ attribute: false })
  reconnectTimeoutID?: number;

  /**
   * The delay in seconds
   */
  get retryDelaySeconds(): number {
    const constant = 1;
    const base = 3;
    const power = this.retryCount;
    /**
     * 0 => 1 seconds
     * 1 => 3 seconds
     * 2 => 9 seconds
     * 3 => 27 seconds
     * 4 => 81 seconds
     * 5 => 243 seconds
     */
    const delay = constant * Math.pow(base, power);
    return delay;
  }

  // #region Render + Styles
  render() {
    // if the "service url" is changed, update our connection
    useEffect(() => this.reconnect(), [this.serviceUrl]);
    // Use "repeat-handshake" to control the interval
    useEffect(() => this.repeatHandshake ? this.handshakeInterval.startInterval() : this.handshakeInterval.stopInterval(), [this.repeatHandshake]);
    // Show a countdown until we refresh
    // useEffect(() => {
    //   // Update message as we countdown
    //   if (this.retryDelayCountdown > 0) {
    //     this.providerConnectionIssue = html`Your connection to the server was dropped. Retrying in ${this.retryDelayCountdown} seconds...`;
    //   }
    //   // Decrement counter
    //   setTimeout(() => {
    //     this.retryDelayCountdown = Math.max(0, this.retryDelayCountdown - 1);
    //   }, 1000)
    // }, [this.retryDelayCountdown])

    return html`
      <slot></slot>
    `
  }

  override connectedCallback() {
    super.connectedCallback();
    console.debug(`[${this.tagName} #${this.instance}] Added to DOM at ${new Date().toLocaleTimeString()}.`);
    if (this.repeatHandshake) {
      this.handshakeInterval.startInterval();
    }
    this.pingInterval.startInterval();
    this.isAttemptingConnection = false;
  }

  override disconnectedCallback() {
    super.disconnectedCallback();
    console.debug(`[${this.tagName} #${this.instance}] Removed from DOM.`);
    this.handshakeInterval.stopInterval();
    this.pingInterval.stopInterval();
    this.isAttemptingConnection = true;
    this.client?.close();
    this.client = undefined;
    clearTimeout(this.reconnectTimeoutID);
    this.reconnectTimeoutID = undefined;
  }

  private reconnect() {
    //console.log('reconnect()')
    // Do nothing
    if (this.isAttemptingConnection) return;
    if (!window.navigator.onLine) return;
    if (this.serviceUrl === undefined || this.serviceUrl === '') return;
    // Verify service URL is a valid URL
    let service: URL | undefined = undefined;
    try {
      service = new URL(`${this.serviceUrl}`, document.location.toString());
      if (service.protocol === 'http:') service.protocol = 'ws:';
      if (service.protocol === 'https:') service.protocol = 'wss:';
      if (!service.protocol.startsWith('ws')) return;
    } catch {
      return;
    }
    //console.log('reconnect() - passed checks')

    // Lock out further attempts
    this.isAttemptingConnection = true;

    // Use this to guide whether or not we delay our re-connect attempt
    let previousConnectionFailed: boolean = false;

    // If a previous client exists
    if (this.client !== undefined) {
      // If the previous client does NOT have an open connection, perhaps it failed
      if (!HasOpenConnection(this.client)) {
        if (this.retryCount >= this.retryLimit) {
          this.providerConnectionIssue = html`Failed to repair connection ${this.retryLimit} times. Please try again later.`;
          // Prevent future attempts
          this.isAttemptingConnection = true;
          // Don't continue to connect
          return;
        }
        this.retryCount += 1;
        previousConnectionFailed = true;
        console.debug(`[${this.tagName} #${this.instance}] Previous connection failed, retry count: `, this.retryCount);
        // Get a `Date` object for when the retry should happen
        //const now = new Date();
        //console.info(now);
        const schedule = new Date();
        schedule.setSeconds(schedule.getSeconds() + this.retryDelaySeconds);
        // Update the label
        this.providerConnectionIssue = html`
          Your connection to the server was dropped.
          Retrying <sl-relative-time date=${schedule.toISOString()}></sl-relative-time> ...
          `;
        //this.retryDelayCountdown = this.retryDelaySeconds;
      }

      // Attempt to close the connection either way
      //console.log('close() called')
      this.client.close();
    }

    const delay = previousConnectionFailed ? this.retryDelaySeconds * 1000 : 0;
    console.debug(`[${this.tagName} #${this.instance}] ⏱ Waiting ${delay}ms until reconnecting...`);
    this.reconnectTimeoutID = window.setTimeout(() => {
      // Do stuff
      console.debug(`[${this.tagName} #${this.instance}] Trying connection...`)
      this.providerConnectionIssue = html`Connecting...`;
      // Establish the connection
      const transport = new WebSocketTransport(service!.href);
      const requestManager = new RequestManager([transport]);
      this.client = new Client(requestManager);
      // Once the client has completely connected, then allow future attempts
      Promise.race([ this.client.requestManager.connectPromise, new Promise((_, reject) => setTimeout(() => reject("Timeout exceeded"), 5 * 1000))])
        .then(() => {
          this.isAttemptingConnection = false;
          this.providerConnectionIssue = undefined;
          const oldRetryCount = this.retryCount;
          // If the previous connection did NOT fail, that means that this was a routine handshake.
          // In that case, maybe the server is healthy again? Subtract our `retryCount` by 1 (not below 0).
          if (!previousConnectionFailed) {
            this.retryCount = Math.max(0, this.retryCount - 1);
          }
          const retryCountDidChange = oldRetryCount !== this.retryCount;
          console.debug(`[${this.tagName} #${this.instance}] Connected! ${retryCountDidChange ? `(retryCount reduced to ${this.retryCount})` : ``}`)
        })
        .catch((err) => {
          this.client?.close();
          this.isAttemptingConnection = false;
          this.providerConnectionIssue = undefined;
          console.warn(`[${this.tagName} #${this.instance}] Reconnect failed: `, err)
        });

      // Watch the connection for failures
      // To prevent manual calls to `.close()` from tripping this, we use the `isAttemptingConnection` lock.
      transport.connection.addEventListener('close', () => this.reconnect());

      // Unlock future attempts
      //this.isAttemptingConnection = false;
    }, delay);
  }

  private _handleInterval() {
    if (!window.navigator.onLine) {
      console.debug(`[${this.tagName} #${this.instance}] ⏭ Handshake skipped. Browser claims to not be connected to a network!`);
      return;
    }
    else if (!this.isAttemptingConnection) {
      console.debug(`[${this.tagName} #${this.instance}] 🤝 ${this.handshakeInterval.timeout / 1000} seconds have passed, time for a routine handshake.`);
    }
    this.reconnect();
  }

  private async _handlePing() {
    // If we're not connected to a network, don't try
    if (!window.navigator.onLine) return;
    // If we don't have a stable connection to the server yet, don't try
    if (this.isAttemptingConnection) return;
    // If we don't have an actual open connection to the server, don't try
    if (this.client === undefined || !HasOpenConnection(this.client)) return;
    console.debug(`[${this.tagName} #${this.instance}] 🏓 Connection deemed stable. Attempting a ping to try to keep connection open.`);
    try {
      // Prevent 2 pings from happening at the same time, if the server is slow or broken
      if (this.lastPingFinished) {
        this.lastPingFinished = false;
        await Ping(this.client);
      }
    }
    catch (err) {
      console.error(`[${this.tagName} #${this.instance}] Ping failed!`);
    }
    finally {
      this.lastPingFinished = true;
    }
  }

  static styles = css`
    :host {
      display: contents;
    }
  `
  // #endregion
}

// #endregion

declare global {
  interface HTMLElementTagNameMap {
    'ep-websocket-provider': EPWebSocketProvider
  }
}
